html, body {
  background-color: #1b1a1c;
}

.backdrop-blur,
[data-reach-dialog-overlay] {
  backdrop-filter: blur(3px);
  z-index: 9999;
}

.transition {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}